import React from 'react';
import { Box, Container, Flex, Link, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box
      py={4}
      backgroundColor={'white'}
    >
      <Container maxW="container.lg">
        <Flex justify="space-between" align="center">
          <Text>&copy; {new Date().getFullYear()} Pollop. All rights reserved.</Text>
          <Flex>
            <Link href="https://www.privacypolicytemplate.net/live.php?token=JOrDtpJhGx26dlS8StIXv7MUKGsgTW5g" target={'_blank'} mx={2} fontWeight={'bold'}>
              Privacy Policy
            </Link>
            <Link href="/terms-of-use" mx={2} fontWeight={'bold'}>
              Terms of use
            </Link>
            <Link href="/eula" mx={2} fontWeight={'bold'}>
              EULA
            </Link>
            <Link href="/contact-us" mx={2} fontWeight={'bold'}>
              Contact Us
            </Link>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Footer;
