import React from 'react';
import { Route, Routes, Navigate, useParams, Link } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ResetPassword from './pages/ResetPassword';
import EmailVerification from './pages/EmailVerification';
import ErrorPage from './pages/ErrorPage';
import logo from './logo2.png';
import { Box, Container, Image, useBreakpointValue } from '@chakra-ui/react';
import ContactUs from './pages/ContactUs';
import BackToTopButton from './components/BackToTopButton';
import TermsOfUse from './pages/TermsOfUse';
import EULA from './pages/EULA';

const RequireToken = ({ children }: { children: JSX.Element }) => {
  const { token } = useParams<{ token: string }>();

  if (!token) {
    // If no token is found, redirect to the main landing page
    return <Navigate to="/" />;
  }

  return children;
};

const App = () => {
  const logoLeft = useBreakpointValue({ base: 0, md: 0, lg: -100 });
  
  return (
    <Box>
       <Box position="sticky" top={0} zIndex={10}>
        <Container maxW="container.lg" position="relative">
          <Box position="absolute" top={0} left={logoLeft}>
            <Link to="/">
              <Image src={logo} alt="Pollop Logo" boxSize="100px" />
            </Link>
          </Box>
        </Container>
      </Box>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/reset-password/*"
          element={
            // <RequireToken>
              <ResetPassword />
            // </RequireToken>
          }
        />
        <Route
          path="/email-verification/:token"
          element={
            <RequireToken>
              <EmailVerification />
            </RequireToken>
          }
        />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/eula" element={<EULA />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <BackToTopButton />
    </Box>
  );
};

export default App;
